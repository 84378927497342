import React from "react";

const ProductPortfolio = () => {
  const brand = {
    name: "Tarazco Accordions",
    tagline: "Elevating Your Musical Experience",
    description:
      "Tarazco Accordions are crafted with precision and artistry, combining tradition with modern innovation. Discover the sound of excellence with our premium models.",
  };

  const accordions = [
    {
      id: 1,
      name: "Tarazco White",
      heroImage:
        "https://media.discordapp.net/attachments/619001738774577154/1313917217284558848/311A8989-FORMATTED-NO-Background.png?ex=6751e044&is=67508ec4&hm=274bbeaadaf1ea8cb31896ed00d806257d94b415d4d442fe4be843054e41fcf5&=&format=webp&quality=lossless&width=823&height=592",
      description:
        "Perfect model for the beginner accordion! ",
      features: [
        { icon: "🎵", title: "Keys", description: "The current keys avaiable are GCF" },
        { icon: "🎼", title: "Lightweight", description: "Enjoyable playing with no drawback!" },
        { icon: "🎹", title: "Button", description: "Sleek design for perfect practices" },
        { icon: "✨", title: "Elegant Design", description: "Striking aesthetics for stage presence." },
      ],
      gallery: [
        "https://camposmusicusa.com/cdn/shop/files/311A8940-FORMATTED.png?v=1723710553&width=1680", // Replace with actual image URLs
        "https://camposmusicusa.com/cdn/shop/files/311A8975-FORMATTED.png?v=1723710553&width=1680",
        "https://camposmusicusa.com/cdn/shop/files/311A8949-FORMATTED.png?v=1723710553&width=1100",
        "https://camposmusicusa.com/cdn/shop/files/311A8948-FORMATTED.png?v=1723710552&width=1100",
    ],
    },
    {
      id: 2,
      name: "Tarazco Black",
      heroImage:
        "https://example.com/images/accordion-b-hero.jpg", // Replace with your actual URL
      description:
        "Model B is crafted for advanced players, offering enhanced bass and precise tones for professional performances.",
      features: [
        { icon: "🎶", title: "Enhanced Bass", description: "Deep, rich bass tones." },
        { icon: "🎼", title: "Lightweight", description: "Portable yet powerful." },
        { icon: "✨", title: "Elegant Design", description: "Striking aesthetics for stage presence." },
      ],
      gallery: [
        "https://example.com/images/accordion-b-front.jpg", // Replace with actual image URLs
        "https://example.com/images/accordion-b-back.jpg",
      ],
    },
  ];

  return (
    <div style={styles.container}>
      {/* Header Section */}
      <header style={styles.header}>
        <h1 style={styles.brandName}>{brand.name}</h1>
        <p style={styles.tagline}>{brand.tagline}</p>
        <p style={styles.brandDescription}>{brand.description}</p>
      </header>

      {/* Product Sections */}
      {accordions.map((accordion) => (
        <section key={accordion.id} style={styles.productSection}>
          {/* Hero Image */}
          <div style={styles.heroContainer}>
            <img
              src={accordion.heroImage}
              alt={accordion.name}
              style={styles.heroImage}
            />
          </div>
          {/* Product Details */}
          <div style={styles.productDetails}>
            <h2 style={styles.productName}>{accordion.name}</h2>
            <p style={styles.productDescription}>{accordion.description}</p>
            <div style={styles.featuresGrid}>
              {accordion.features.map((feature, index) => (
                <div key={index} style={styles.featureCard}>
                  <div style={styles.icon}>{feature.icon}</div>
                  <h3 style={styles.featureTitle}>{feature.title}</h3>
                  <p style={styles.featureDescription}>{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
          {/* Gallery */}
          <div style={styles.gallery}>
            {accordion.gallery.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`${accordion.name} view ${index + 1}`}
                style={styles.galleryImage}
              />
            ))}
          </div>
        </section>
      ))}
      {/* Contact Us Section */}
        <div style={styles.contactSection}>
        <h2 style={styles.sectionTitle}>Contact Us</h2>
        <p style={styles.contactInfo}>
            <strong>Phone:</strong> 480-844-2500
        </p>
        <p style={styles.contactInfo}>
            <strong>Toll-Free:</strong> 1-800-526-2005
        </p>
        <p style={styles.contactInfo}>
            <strong>Email:</strong> <a href="mailto:sales@valleymusicdistributors.com" style={styles.contactLink}>sales@valleymusicdistributors.com</a>
        </p>
        </div>


      {/* Footer */}
      <footer style={styles.footer}>
        <p>&copy; {new Date().getFullYear()} Tarazco Accordions. All rights reserved.</p>
      </footer>
    </div>
  );
};

// Styles for Brand Portfolio
const styles = {

    contactSection: {
        padding: "40px 20px",
        backgroundColor: "#f9f9f9",
        textAlign: "center",
        borderTop: "3px solid #ff4500",
      },
      contactInfo: {
        fontSize: "1.2rem",
        margin: "10px 0",
        color: "#333",
      },
      contactLink: {
        color: "#ff4500",
        textDecoration: "none",
        fontWeight: "bold",
      },
      
  container: {
    fontFamily: "'Poppins', sans-serif",
    backgroundColor: "#ffffff",
    color: "#333",
    lineHeight: "1.6",
    margin: 0,
    padding: 0,
  },
  header: {
    textAlign: "center",
    padding: "40px 20px",
    backgroundColor: "#f9f9f9",
    borderBottom: "3px solid #ff4500",
  },
  brandName: {
    fontSize: "3rem",
    color: "#111",
    marginBottom: "10px",
  },
  tagline: {
    fontSize: "1.2rem",
    fontStyle: "italic",
    color: "#666",
    marginBottom: "20px",
  },
  brandDescription: {
    fontSize: "1.2rem",
    maxWidth: "800px",
    margin: "0 auto",
    color: "#555",
  },
  productSection: {
    padding: "40px 20px",
    borderBottom: "1px solid #ddd",
  },
  heroContainer: {
    textAlign: "center",
    marginBottom: "20px",
  },
  heroImage: {
    maxWidth: "600px",
    height: "auto",
  },
  
  productDetails: {
    textAlign: "center",
    marginBottom: "20px",
  },
  productName: {
    fontSize: "2rem",
    color: "#111",
    marginBottom: "10px",
  },
  productDescription: {
    fontSize: "1.2rem",
    color: "#555",
    marginBottom: "20px",
  },
  featuresGrid: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    flexWrap: "wrap",
  },
  featureCard: {
    maxWidth: "250px",
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  icon: {
    fontSize: "2.5rem",
    marginBottom: "10px",
  },
  featureTitle: {
    fontSize: "1.2rem",
    marginBottom: "5px",
    color: "#111",
  },
  featureDescription: {
    fontSize: "1rem",
    color: "#666",
  },
  gallery: {
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    flexWrap: "wrap",
    marginTop: "20px",
  },
  galleryImage: {
    maxWidth: "200px",
    borderRadius: "8px",
    border: "1px solid #ddd",
  },
  footer: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderTop: "1px solid #ddd",
    fontSize: "0.9rem",
    color: "#666",
  },
};

export default ProductPortfolio;
